//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatTimeShort, formatDateShort, dayjs } from '@/helpers/dateTime'
import { formatNumber } from '@/helpers/number'
export default {
  name: 'HomeCalendarEventActivity',
  props: {
    dayItem: {
      type: Object,
      default: Object
    },
    loading: {
      type: Boolean
    }
  },
  data () {
    const dateTime = dayjs()
    return {
      selectedItem: null,
      currentTab: 'กิจกรรม',
      dayItems: {
        dateTh: dateTime.locale(this.$i18n.locale).format('DD'),
        monthTh: dateTime.locale(this.$i18n.locale).format('MMMM'),
        customData: null
      },
      rawData: null,
      eventItems: [],
      oppdayItems: []
    }
  },
  computed: {
    itemList () {
      return this.dayItem && this.dayItem.customData ? this.dayItem.customData || [] : []
    },
    defaultType () {
      return this.dayItem ? this.dayItem[0].type : ''
    },
    eventList () {
      return this.dayItem && this.dayItem.event ? this.dayItem.event || [] : []
    },
    eventOppDay () {
      return this.dayItem && this.dayItem.oppday ? this.dayItem.oppday || [] : []
    }
  },
  watch: {
    dayItem () {
      this.activeTab()
    }
  },
  mounted () {
    this.activeTab()
  },
  methods: {
    formatTimeShort,
    formatDateShort,
    formatNumber,
    clickOnOppDay (item) {
      const oppdayCalendarURL = item.playerUrl
      window.open(oppdayCalendarURL, '_blank')
    },
    onSelectedItem (item) {
      this.selectedItem = item
      const url = this.localePath(`/about/event-calendar/event/eventdetails?id=${this.selectedItem.eventId}`)
      window.open(url, '_blank')
      this.handleEventTracking('home26', item.name)
      // this.$nextTick(() => {
      //   this.$refs.modalEvent.show()
      // })
    },
    activeTab () {
      if (this.currentTab === 'กิจกรรม') {
        if (this.eventList && this.eventList.length > 0 && this.eventOppDay && this.eventOppDay.length > 0) {
          this.currentTab = 'กิจกรรม'
        } else if (this.eventList && this.eventList.length > 0) {
          this.currentTab = 'กิจกรรม'
        } else if (this.eventOppDay && this.eventOppDay.length > 0) {
          this.currentTab = 'Opportunity Day'
        }
      } else if (this.currentTab === 'Opportunity Day') {
        if (this.eventList && this.eventList.length > 0 && this.eventOppDay && this.eventOppDay.length > 0) {
          this.currentTab = 'Opportunity Day'
        } else if (this.eventOppDay && this.eventOppDay.length > 0) {
          this.currentTab = 'Opportunity Day'
        } else if (this.eventList && this.eventList.length > 0) {
          this.currentTab = 'กิจกรรม'
        }
      }
    },
    setHolidayClass (day) {
      const { el, date: elDate } = day
      const foundHoliday = this.holidayCalendar.find(d => dayjs(d.date).format('DD/MM/YYYY') === dayjs(elDate).format('DD/MM/YYYY'))
      if (foundHoliday) {
        this.addHolidayClass(el)
      }
    },
    addHolidayClass (dayEl) {
      const parentElement = dayEl?.parentElement
      if (!parentElement) { return }
      dayEl.parentElement.classList.add('vc-day-holiday')
    },
    dayClicked (day) {
      const customData = {}
      day.attributes.forEach(item => Object.assign(customData, item.customData))
      this.dayItems = {
        ...day,
        customData: Object.keys(customData).length ? customData : null
      }
      // console.log('🚀 ~ customData', customData)

      const dateTh = dayjs().locale(this.$i18n.locale).format('DD')
      const monthTh = dayjs().locale(this.$i18n.locale).format('MMMM')
      if (day && this.dayItem.ariaLabel) {
        // const dateTh = this.dayItem.ariaLabel.split(' ')
        if (dayjs(day.id).isValid()) {
          this.dayItems.dateTh = dayjs(day.id).locale(this.$i18n.locale).format('DD')
          this.dayItems.monthTh = dayjs(day.id).locale(this.$i18n.locale).format('MMMM')
        } else {
          this.dayItems.dateTh = dateTh
          this.dayItems.monthTh = monthTh
        }
      } else {
        this.dayItems.dateTh = dateTh
        this.dayItems.monthTh = monthTh
      }

      this.setHolidayClass(day)
    },
    handleEventTracking (name, title) {
      if (title) {
        this.$personalized.clickElement({
          name,
          optionLang: {
            title
          }
        })
      } else {
        this.$personalized.clickElement({
          name
        })
      }
    },
    onClose () {
      this.$refs.modalEvent.hide()
    }
  }
}
