//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import dayjs from 'dayjs'
import { dayjs } from '@/helpers/dateTime'
const { timezone } = require('@/config/datetime')
export default {
  components: {
    VCalendar: () => process.client ? import('~/lib/v-calendar-custom/lib/components/date-picker.umd') : Promise.resolve({ render: h => h('div') })
  },
  props: {
    attributes: {
      type: Array,
      default: () => {}
    },
    isEventActivity: {
      type: Boolean,
      default: false
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    }
  },
  data () {
    return {
      selectedDateId: null,
      timezone,
      dateTimePicker: {
        masks: {
          weekdays: this.$i18n.locale === 'th' ? 'W' : 'WWW'
        }
      }
    }
  },
  updated () {
    this.$nextTick(() => {
      this.setDefaultDay()
    })
  },
  mounted () {
    setTimeout(() => {
      this.setDefaultDay()
    }, 250)
  },
  methods: {
    dayjs,
    setDefaultDay () {
      if (this.selectedDateId) {
        setTimeout(() => {
          this.activeSelectedDay()
        }, 120)
      }
      this.setToday()
    },
    handleChangeFromPage (event) {
      this.$emit('changeFromPage', event)
    },
    handleClickDay (event) {
      const dateId = event.id
      this.selectedDateId = dateId
      setTimeout(() => {
        this.activeSelectedDay()
      }, 120)
      this.$emit('dayClicked', event)
    },
    activeSelectedDay () {
      const selectedClassName = 'active-day-selected'
      const selectorWrapper = '.mini-calendar-tabs-content .tab-pane.active'
      const elToRemoveClass = document.querySelector(`${selectorWrapper} div.vc-day.${selectedClassName}`)
      const elToAddClass = document.querySelector(`${selectorWrapper} .id-${this.selectedDateId}`)
      elToRemoveClass?.classList?.remove(selectedClassName)
      elToAddClass?.classList?.add(selectedClassName)
    },
    async setToday () {
      await this.clearToday()
      const { date } = this.$store.state
      const today = dayjs(date)
      const selectorWrapper = '.mini-calendar-tabs-content .tab-pane.active'
      const elToday = document.querySelector(`${selectorWrapper} .id-${today.format('YYYY-MM-DD')}`)
      elToday?.classList?.add('is-today', 'today-custom')
      // const todayElList = [].slice.call(document.querySelectorAll(`.id-${today.format('YYYY-MM-DD')}`))
      // todayElList.forEach((element) => {
      //   if (element) {
      //     element?.classList?.add('is-today', 'today-custom')
      //   }
      // })
    },
    clearToday () {
      const selectorWrapper = '.mini-calendar-tabs-content .tab-pane.active'
      const elToday = document.querySelector(`${selectorWrapper} .vc-weeks .vc-day.is-today`)
      elToday?.classList?.remove('is-today')
      // const dayList = [].slice.call(document.querySelectorAll('.vc-weeks .vc-day.is-today'))
      // dayList.forEach((element) => {
      //   element.classList.remove('is-today')
      // })
    },
    transitionEnd () {
      this.setDefaultDay()
    }
  }
}
