//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import { dayjs } from '@/helpers/dateTime'
export default {
  name: 'TabCalendar',
  data () {
    const dateTime = dayjs()
    return {
      loading: true,
      selectedStockDateId: null,
      selectedEventDateId: null,
      eventHoliday: [],
      attributes: [],
      attributesEvent: [],
      loadedMonth: [],
      dayItem: {
        dateTh: dateTime.locale(this.$i18n.locale).format('DD'),
        monthTh: dateTime.locale(this.$i18n.locale).format('MMMM'),
        customData: null
      },
      dayEventItem: {
        dateTh: dateTime.locale(this.$i18n.locale).format('DD'),
        monthTh: dateTime.locale(this.$i18n.locale).format('MMMM'),
        yearTh: dateTime.locale(this.$i18n.locale).format('YYYY'),
        customData: null
      },
      currentTab: 'ปฏิทินหลักทรัพย์',
      minDate: dateTime.add(-1, 'month').$d,
      maxDate: dateTime.add(4, 'month').$d,
      eventMinDate: dateTime.$d,
      eventMaxDate: dateTime.add(6, 'month').$d,
      // eventMaxDate: new Date(new Date().setMonth(new Date().getMonth() + 6)),
      month: null,
      year: null
    }
  },
  computed: {
    ...mapGetters({
      stockCalendar: 'home/stock-calendar/getStockCalendar',
      eventCalendar: 'home/stock-calendar/getEventCalendar',
      eventOppDay: 'home/stock-calendar/getEventOppDay'
    }),
    xCalendar () {
      return this.stockCalendar.xCalendar || []
    },
    meetingCalendar () {
      return this.stockCalendar.meetingCalendar || []
    },
    holidayCalendar () {
      return this.stockCalendar.holidayCalendar || []
    },
    // OppDayCalendar () {
    //   return this.eventOppDay.holidayCalendar || []
    // },
    specificPath () {
      return this.$route.path || ''
    },
    isTCHorTSD () {
      return this.specificPath.includes('/tsd/overview') || this.specificPath.includes('/tch/overview')
    },
    nameSecondTab () {
      if (this.isTCHorTSD) {
        return this.$t('tsd.overviewPage.setCalendarHoliday')
      }
      return this.$t('homePage.calendar.eventCalendar')
    },
    eventCalendarLink () {
      if (this.isTCHorTSD) {
        return this.localePath('/about/event-calendar/holiday')
      }
      return this.localePath('/about/event-calendar/event/overview')
    },
    viewMore () {
      if (this.currentTab === 'ปฏิทินหลักทรัพย์') {
        return this.localePath('/market/stock-calendar/x-calendar')
      } else {
        return this.eventCalendarLink
      }
    }
  },
  mounted () {
    this.newTabChange('ปฏิทินหลักทรัพย์')
    setTimeout(() => {
      const arrayCustomDataNow = this.attributes.filter((item) => {
        return dayjs(item.dates).$d.toDateString() === dayjs().$d.toDateString()
      })

      if (arrayCustomDataNow && arrayCustomDataNow.length) {
        this.dayClicked({ attributes: [...arrayCustomDataNow] })
      } else {
        this.dayClicked({ attributes: [] })
      }
    }, 1500)
    this.registerEvent()
    // await this.renderEventCalendar()
    // this.renderEventOppDay()
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    ...mapActions({
      getStockCalendar: 'home/stock-calendar/getStockCalendar',
      getEventCalendar: 'home/stock-calendar/getEventCalendar',
      getEventOppDay: 'home/stock-calendar/getEventOppDay'
    }),
    registerEvent () {
      const externalLinkEle = document.querySelector('.external-link')
      if (externalLinkEle) {
        document.querySelector('.external-link').addEventListener('click', this.handleExternalLinkClick)
      }
    },
    removeEvent () {
      const externalLinkEle = document.querySelector('.external-link')
      if (externalLinkEle) {
        document.querySelector('.external-link').removeEventListener('click', this.handleExternalLinkClick)
      }
    },
    handleExternalLinkClick (e) {
      e.stopPropagation()
      const { Tab } = require('bootstrap')
      const el = this.$refs.calendarTab1.$el
      const tab = Tab.getOrCreateInstance(el)
      tab.show()
    },
    newTabChange (tab) {
      this.currentTab = tab
      if (this.currentTab === 'ปฏิทินกิจกรรม') {
        this.defaultDateOfEventCalendar()
      }
      this.$emit('tabChange', tab)
    },
    defaultDateOfEventCalendar () {
      if (this.selectedEventDateId) {
        return
      }
      const arrayCustomDataNow = this.attributesEvent.filter((item) => {
        return dayjs(item.dates).$d.toDateString() === dayjs().$d.toDateString()
      })
      if (arrayCustomDataNow && arrayCustomDataNow.length) {
        this.eventDayClicked({ attributes: [...arrayCustomDataNow] })
      } else {
        this.eventDayClicked({ attributes: [] })
      }
      const hasOppDay = Object.prototype.hasOwnProperty.call(this.dayEventItem, 'oppday')
      const hasEvent = Object.prototype.hasOwnProperty.call(this.dayEventItem, 'event')
      setTimeout(() => {
        if (!hasOppDay && !hasEvent) {
          this.eventDayClicked(dayjs().format('DD/MM/YYYY'))
        }
      }, 1000)
    },
    defaultDateOfStockCalendar () {
      if (this.selectedStockDateId) {
        return
      }
      const arrayCustomDataNow = this.attributes.filter((item) => {
        return dayjs(item.dates).$d.toDateString() === dayjs().$d.toDateString()
      })
      if (arrayCustomDataNow && arrayCustomDataNow.length) {
        this.dayClicked({ attributes: [...arrayCustomDataNow] })
      }
    },
    setHolidayClass (day) {
      const { el, date: elDate } = day
      const foundHoliday = this.holidayCalendar.find(d => dayjs(d.date).format('DD/MM/YYYY') === dayjs(elDate).format('DD/MM/YYYY'))
      if (foundHoliday) {
        this.addHolidayClass(el)
      }
    },
    addHolidayClass (dayEl) {
      const parentElement = dayEl?.parentElement
      if (!parentElement) { return }
      dayEl.parentElement.classList.add('vc-day-holiday')
    },
    dayClicked (day) {
      this.selectedStockDateId = day.id || this.selectedStockDateId // We need id of param 'day', when click on day the object 'day' will have attr id
      const customData = {}
      day.attributes.forEach(item => Object.assign(customData, item.customData))
      this.dayItem = {
        ...day,
        customData: Object.keys(customData).length ? customData : null
      }
      // console.log('🚀 ~ customData', customData)

      const dateTh = dayjs().locale(this.$i18n.locale).format('DD')
      const monthTh = dayjs().locale(this.$i18n.locale).format('MMMM')
      // console.log('🚀 ~ this.dayItem.ariaLabel:', this.dayItem)
      if (day && this.dayItem.ariaLabel) {
        // const dateTh = this.dayItem.ariaLabel.split(' ')
        if (dayjs(day.id).isValid()) {
          this.dayItem.dateTh = dayjs(day.id).locale(this.$i18n.locale).format('DD')
          this.dayItem.monthTh = dayjs(day.id).locale(this.$i18n.locale).format('MMMM')
        } else {
          this.dayItem.dateTh = dateTh
          this.dayItem.monthTh = monthTh
        }
      } else {
        this.dayItem.dateTh = dateTh
        this.dayItem.monthTh = monthTh
      }

      // set holiday style
      this.setHolidayClass(day)
    },
    async changeFromPage ({ month, year }) {
      if (this.month !== month || this.year !== year) {
        this.month = month
        this.year = year
        this.loading = true
        await this.getStockCalendar({ month, year })
        await this.renderCalendar()
        this.defaultDateOfStockCalendar()
        this.loading = false
      }
    },
    async changeFromCalendar ({ month, year }) {
      if (!this.eventCalendar.length) {
        await this.getEventCalendar().then(() => {
          const uniqueDateAll = [...new Set(this.eventCalendar.map((f) => { return f.date }))]
          let event = uniqueDateAll.map((date) => {
            return {
              type: 'event',
              dot: {
                style: {
                  'background-color': '#0E95E1',
                  display: 'unset !important'
                }
              },
              dates: dayjs(date).format('YYYY-MM-DD'),
              customData: this.eventCalendar.filter(f => dayjs(f.date).format('YYYY/MM/DD') === dayjs(date).format('YYYY/MM/DD')).map(item => item.events)
            }
          })
          event = this.removeDuplicates(event)
          // const list = this.eventCalendar.map()
          this.attributesEvent = [...this.attributesEvent, ...event]
          // this.renderEventCalendar()
        })
      }
      if (this.month !== month || this.year !== year) {
        this.month = month
        this.year = year
        const payload = {
          year,
          month
        }
        this.getOppDay(payload)
      } else {
        const payload = {
          year: dayjs().$y,
          month: dayjs().$M + 1
        }
        this.getOppDay(payload)
      }
      if (!this.eventHoliday.length || this.year !== year) {
        this.getHoliday(year, this.year !== year)
      }
    },
    removeDuplicates (array) {
      const uniqueArray = array.reduce((accumulator, current) => {
        if (!accumulator.some(item => item.dates === current.dates)) {
          accumulator.push(current)
        }
        return accumulator
      }, [])
      return uniqueArray
    },
    async getHoliday (year, isNextYear) {
      await this.$axios.get(
        'api/set/holiday/year/' + year + '?lang=' + this.$i18n.locale
      )
        .then((res) => {
          // this.attributesEvent = [...this.attributesEvent, ...res]
          const data = res ? res.data : []
          if (isNextYear) {
            this.eventHoliday = [...this.eventHoliday, ...data]
          } else {
            this.eventHoliday = data
          }
          const attr = data.map((item) => {
            return {
              type: 'holiday',
              content: {
                class: 'custom-day-holiday'
              },
              dates: item.date,
              // highlight: true,
              customData: {
                holiday: true
              },
              popover: {
                label: item.description,
                placement: this.$device.isDesktop ? 'bottom' : 'auto-end'
              }
            }
          })
          this.attributesEvent = [...this.attributesEvent, ...attr]
        }).catch(() => {
          return []
        })
    },
    async getOppDay (payload) {
      const strDate = `${payload.month}-${payload.year}`
      if (!this.loadedMonth.includes(strDate)) {
        await this.getEventOppDay(payload).then(() => {
          const attrEventOppDayData = this.eventOppDay.map((item) => {
            return {
              type: 'oppday',
              dot: {
                style: {
                  'background-color': '#D19753'
                }
              },
              dates: item.date,
              customData: item.videos
            }
          })
          // console.log('attrEventOppDayData', attrEventOppDayData)
          this.attributesEvent = [...this.attributesEvent, ...attrEventOppDayData]
          this.loadedMonth.push(strDate)
        // Array.prototype.push.apply(this.attributesEvent, attrEventOppDayData)
        })
      }
    },
    renderCalendar () {
      const attrXCalendar = this.xCalendar.map((item) => {
        return {
          type: 'XCalendar',
          dot: 'green',
          dates: item.date,
          customData: {
            types: item.types
          }
        }
      })
      const attrMeetingCalendar = this.meetingCalendar.map((item) => {
        return {
          type: 'MeetingCalendar',
          dot: {
            style: {
              'background-color': '#fa7210'
            }
          },
          dates: item.date,
          customData: {
            meetings: item.meetings
          }
        }
      })
      const attrHolidayCalendar = this.holidayCalendar.map((item) => {
        return {
          key: item.date,
          type: 'HolidayCalendar',
          content: {
            class: 'custom-day-holiday'
          },
          dates: item.date,
          // highlight: true,
          customData: {
            holiday: true
          },
          popover: {
            label: item.description,
            placement: this.$device.isDesktop ? 'bottom' : 'auto-end'
          }
        }
      })
      this.attributes = [
        ...attrXCalendar,
        ...attrMeetingCalendar,
        ...attrHolidayCalendar
        // {
        //   highlight: true,
        //   dates: new Date()
        // }
      ]
    },
    eventDayClicked (day) {
      // console.log('this.attributesEvent', this.attributesEvent)
      this.selectedEventDateId = day.id || this.selectedEventDateId // We need id of param 'day', when click on day the object 'day' will have attr id
      this.loading = true
      const selectDate = dayjs(day.id).format('YYYY/MM/DD')
      const filter = this.attributesEvent.filter(f => dayjs(f.dates).format('YYYY/MM/DD') === selectDate)
      const result = Object.groupBy(filter, ({ type }) => type)
      // console.log('filter', filter)
      // this.dayEventItem.customData = Object.keys(result).length ? result : null
      this.dayEventItem = result
      const dateTh = dayjs().locale(this.$i18n.locale).format('DD')
      const monthTh = dayjs().locale(this.$i18n.locale).format('MMMM')
      const yearTh = dayjs().locale(this.$i18n.locale).format('YYYY')
      // console.log('result', result)
      if (day && this.dayEventItem) {
        if (dayjs(day.id).isValid()) {
          this.dayEventItem.dateTh = dayjs(day.id).locale(this.$i18n.locale).format('DD')
          this.dayEventItem.monthTh = dayjs(day.id).locale(this.$i18n.locale).format('MMMM')
          const year = dayjs(day.id).locale(this.$i18n.locale).format('YYYY')
          this.dayEventItem.yearTh = this.$i18n.locale === 'th' ? parseInt(year) + 543 : year
        } else {
          this.dayEventItem.dateTh = dateTh
          this.dayEventItem.monthTh = monthTh
          this.dayEventItem.yearTh = this.$i18n.locale === 'th' ? parseInt(yearTh) + 543 : yearTh
        }
      } else {
        this.dayEventItem.dateTh = dateTh
        this.dayEventItem.monthTh = monthTh
        this.dayEventItem.yearTh = this.$i18n.locale === 'th' ? parseInt(yearTh) + 543 : yearTh
      }
      this.loading = false
      this.setHolidayEventClass(day)
    },
    setHolidayEventClass (day) {
      const { el, date: elDate } = day
      const foundHoliday = this.eventHoliday.find(d => dayjs(d.date).format('DD/MM/YYYY') === dayjs(elDate).format('DD/MM/YYYY'))
      if (foundHoliday) {
        this.addHolidayClass(el)
      }
    },

    eventTrackingSecondTab (e) {
      if (this.isTCHorTSD) {
        this.$personalized.clickElement({
          name: 'tsdOverview9'
        })
      }
    },

    eventTrackingViewMore () {
      this.$personalized.clickElement({
        name: this.currentTab === 'ปฏิทินหลักทรัพย์' ? 'home24' : 'home25'
      })
    }
  }
}
