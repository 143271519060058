//
//
//
//
//
//
//
//

export default {
  name: 'IconClock2'
  // serverCacheKey: () => 'IconTriangle-v1'
}
