import { render, staticRenderFns } from "./EventActivity.vue?vue&type=template&id=960308da&scoped=true&"
import script from "./EventActivity.vue?vue&type=script&lang=js&"
export * from "./EventActivity.vue?vue&type=script&lang=js&"
import style0 from "./EventActivity.vue?vue&type=style&index=0&id=960308da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "960308da",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tab: require('/app/components/NavTabs/Tab.vue').default,Tabs: require('/app/components/NavTabs/Tabs.vue').default,Placeholder: require('/app/components/Placeholder/Placeholder.vue').default,IconClock2: require('/app/components/Icon/Clock2.vue').default,Message: require('/app/components/Message/index.vue').default,TabPane: require('/app/components/NavTabs/TabPane.vue').default,TabContent: require('/app/components/NavTabs/TabContent.vue').default,IconClose: require('/app/components/Icon/Close.vue').default,Button: require('/app/components/Button/Button.vue').default,IconDogTag: require('/app/components/Icon/DogTag.vue').default,Card: require('/app/components/Card/Card.vue').default,Modal: require('/app/components/Modal/Modal.vue').default})
