import { render, staticRenderFns } from "./CalendarTab.vue?vue&type=template&id=28f81c2c&"
import script from "./CalendarTab.vue?vue&type=script&lang=js&"
export * from "./CalendarTab.vue?vue&type=script&lang=js&"
import style0 from "./CalendarTab.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tab: require('/app/components/NavTabs/Tab.vue').default,Tabs: require('/app/components/NavTabs/Tabs.vue').default,HomeCalendar: require('/app/components/Pages/Home/Calendar/Calendar.vue').default,HomeCalendarActivity: require('/app/components/Pages/Home/Calendar/Activity.vue').default,TabPane: require('/app/components/NavTabs/TabPane.vue').default,HomeCalendarEventActivity: require('/app/components/Pages/Home/Calendar/EventActivity.vue').default,ContentLazy: require('/app/components/ContentLazy.vue').default,TabContent: require('/app/components/NavTabs/TabContent.vue').default,ButtonViewMore: require('/app/components/Button/ViewMore.vue').default})
