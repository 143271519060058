//
//
//
//
//
//
//
//

export default {
  name: 'IconDogTag'
}
